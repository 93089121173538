<template>
    <div style="text-align: center; margin-top: 50px">
        <v-img :src="require('../assets/logo.png')" max-width="400" max-height="250" alt="Edison" class="vx-login-logo"></v-img>
        <v-flex xs12 sm6 offset-sm3 align-center>
            <v-form ref="loginForm" class='ma-8'>
                <v-text-field v-model="username" placeholder="E-mail" :rules="emailRules" label="E-mail"></v-text-field>
                <v-text-field v-model="password" placeholder="Mot de passe" :append-icon="show ? 'visibility_off' : 'visibility'"
                              :rules="passwordRules" :type="show ? 'text' : 'password'"
                              label="Mot de passe" @click:append="show = !show"></v-text-field>
                <v-btn :loading="loading" color='#e41319' @click="login()" class="white--text" >SE CONNECTER</v-btn>
            </v-form>
            <div>Version : {{ require('../../package.json').version }}</div>
        </v-flex>
    </div>
</template>
<script>

    export default {
        name: 'login',
        data() {
            return {
                username: '',
                emailRules: [
                    v => !!v || "L'email est requis",
                    v => this.$services.powerLib.regex.getRegex('email').test(v) || "L'email n'est pas valide"
                ],
                password: '',
                passwordRules: [
                    v => !!v || "Le mot de passe est requis"
                ],
                show: false,
                loading: false,
            }
        },
        methods: {
            /**
             * Ne gère que l'authentification de l'utilisateur
             */
            login() {
                if(this.$refs.loginForm.validate()) {
                    this.loading = true;
                    this.$auth.login(this.username, this.password, 'home')
                        .then( () => {
                            this.loading = false;
                        }).catch(()=>{
                            this.$services.powerLib.notification.showSnackbar({
                                message : 'Erreur de connexion',
                                type : 'error',
                            })
                            this.loading = false
                        });
                }
            }
        }
    }
</script>

<style lang="scss">
    .vx-login-logo {
        margin: auto;
    }
</style>
